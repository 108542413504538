.spectrogram_drawer {
  padding: 2px;
}

.container {
  width: 98%;
  height: 40vh;
  margin-top: 5vh;
  /* border: 2px solid gray; */
}

ul {
  list-style-type: disclosure-closed;
}

li:hover {
  background: #83b5e8;
}

.selectedAudio {
  background: #1564b3;
  color: aliceblue;
}

.bottom_panel {
  font-size: medium;
}

.canvasspec {
  flex: 1 1 auto;
  background-color: black;
  width: 97%;
  height: 34vh;
}

canvas {
  width: 90%;
  height: 50%;
}